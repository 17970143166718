import React from "react";
import Layout from "../containers/layout";

import MyForm from "./form";
import { graphql, Link, useStaticQuery } from 'gatsby'
import ContactModal from '../components/generic/ContactModal/ContactModal';
import SEO from '../components/seo';

import ContentCard from '../components/ContentCardTest/ContentCard';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query contactInformation {
      site: sanityContactPage(_id: {regex: "/(drafts.|)contactPage/"}) {
          title
          pageIntroduction {
            subTitle
            title
            description
            showSocials
            buttonText
            thumbNail {
              asset {
                url
              }
            }
            imageEffect
          }
        }
      }
    `);

  const [showModal, setShowModal] = React.useState(false);
  const handleModalClose = React.useCallback(() => {
    setShowModal(false);
  }, []);

  const pageIntroduction = data.site.pageIntroduction;

  return(
    <Layout>
      <SEO title="Contact" />
      <div className="about-us">
        <div className="wrap">
          <div className="row">
          {pageIntroduction?.thumbNail ? (
            <ContentCard horizontal size="medium" image={pageIntroduction.thumbNail.asset.url}>
              <div>
                <h3>{pageIntroduction.title}</h3>
                <p>{pageIntroduction.description}</p>
                {pageIntroduction.showSocials && (
                  <ul className="socials">
                    <li className="socials__item">
                      <a href="https://www.instagram.com/antjemachen" className="socials__link socials__link--insta"></a>
                    </li>
                    <li className="socials__item">
                      <a href="https://www.facebook.com/Antje-Machen-zwangerschapsadvies-112624847037326/" className="socials__link socials__link--facebook"></a>
                    </li>
                  </ul>
                )}

                <button onClick={() => setShowModal(true)} className='button'>Neem contact op!</button>
              </div>
            </ContentCard>
          ): null}
          </div>
        </div>

        <ContactModal
          open={showModal}
          onClose={handleModalClose}
        />
      </div>
    </Layout>
  )
}

export default Contact
